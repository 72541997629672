/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  ADD_ITEM (state, item) {
    state.Category.unshift(item)
  },
  SET_CATEGORY (state, Category) {
    state.Category = Category
  },
  SELECTED_CATEGORY (state, selectCategories) {
    state.selectCategories = selectCategories
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_CATEGORY (state, category) {
    const productIndex = state.Category.findIndex((p) => p.id === category.id)
    Object.assign(state.Category[productIndex], category)
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.Category.findIndex((p) => p.id === itemId)
    state.Category.splice(ItemIndex, 1)
  }
}
